import {
  FIREBASE_API_KEY,
  FIREBASE_APP_CHECK_SITE_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_AUTH_USE_EMULATOR,
  FIREBASE_DISABLE_APP_CHECK,
  FIREBASE_FUNCTIONS_USE_EMULATOR,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIRESTORE_USE_EMULATOR,
  VUE_APP_REVIEW_ENV,
  FIREBASE_APP_CHECK_DEBUG_TOKEN,
} from "./config";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { initializeApp } from "firebase/app";
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  getToken,
} from "firebase/app-check";
import {
  getFirestore,
  connectFirestoreEmulator,
  initializeFirestore,
} from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import Honeybadger from "@honeybadger-io/js";

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
};
let appCheck = null;

export const app = initializeApp(firebaseConfig);

/**
 * The AppCheck is disabled locally as it would mean updating the debug tokens
 * in the Project Console.
 * Add the AppCheck token that appears in the dev console to the firebase AppCheck dashboard.
 * The `FIREBASE_APPCHECK_DEBUG_TOKEN` must be declared before calling the `initializeAppCheck` method.
 * AppCheck is always on by default in case the environment key is missing. You have to disabled it
 * with `FIREBASE_DISABLE_APP_CHECK=true`
 */
if (VUE_APP_REVIEW_ENV === "true") {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = FIREBASE_APP_CHECK_DEBUG_TOKEN;
}

if (!FIREBASE_DISABLE_APP_CHECK) {
  appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(FIREBASE_APP_CHECK_SITE_KEY),
    isTokenAutoRefreshEnabled: true,
  });
}

if (FIRESTORE_USE_EMULATOR) {
  /**
   * This is to allow Cypress to run the tests using the emulator.
   * https://stackoverflow.com/questions/59336720/cant-use-cypress-to-test-app-using-using-firestore-local-emulator
   */
  initializeFirestore(app, { experimentalForceLongPolling: true });
}

export const functions = getFunctions(app, "europe-west2");
export const auth = getAuth();
export const db = getFirestore();

// Port 9099
if (FIREBASE_AUTH_USE_EMULATOR) {
  connectAuthEmulator(auth, "http://localhost:9099");
}

// Port 9090
if (FIRESTORE_USE_EMULATOR) {
  connectFirestoreEmulator(db, "localhost", 9090);
}

//Port 5001
if (FIREBASE_FUNCTIONS_USE_EMULATOR) {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

/**
 * Function to retry fetching App Check token with exponential backoff.
 * - First attempt uses cached token (forceRefresh: false)
 * - Subsequent attempts force refresh only if needed
 */
export const getAppCheckToken = async (maxRetries = 3, delay = 2000) => {
  // Return empty token when using Firebase Emulator or AppCheck is disabled
  if (!appCheck) {
    return "";
  }

  let attempt = 0;
  let forceRefresh = false; // Start without forcing refresh

  while (attempt < maxRetries) {
    try {
      const tokenResponse = await getToken(appCheck, forceRefresh);

      if (tokenResponse.token) {
        return tokenResponse.token;
      }
    } catch (error) {
      const errorDetails = {
        errorName: error.name,
        errorMessage: error.message,
        timestamp: new Date().toISOString(),
        attempt: attempt + 1,
        forceRefresh,
      };

      if (attempt === maxRetries - 1) {
        Honeybadger.notify(error, {
          name: "firebase.js",
          message: `Failed to get AppCheck token after ${maxRetries} retries`,
          context: {
            isIncognito: window.chrome && window.chrome.extension === undefined,
            params: {
              appCheck,
              attempt,
              maxRetries,
              errorDetails,
            },
          },
        });
        return "";
      }
    }

    attempt++;
    // Only force refresh after first failure
    forceRefresh = true;

    // Linear backoff instead of exponential (2s, 4s, 6s)
    const backoffDelay = delay * attempt;
    await new Promise((res) => setTimeout(res, backoffDelay));
  }

  return ""; // Fallback empty token if all retries fail
};
